import React, {useEffect, useState, useContext} from 'react'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faTrash, faHandshake, faPenToSquare, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form, Field } from 'formik';
import CustomLoader from '../layouts/CustomLoader';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';
import usePaginationComponentOptions from '../layouts/paginationComponentOptions';
import { useTranslation } from 'react-i18next';

const Partners = () => {
/***************************************************************************************************************************/
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const [data, setData]            = useState([]);
    const [loading, setLoading]      = useState(false);
    const [totalRows, setTotalRows]  = useState(0);
    const [perPage, setPerPage]      = useState(10);
    const [_search, set_search]      = useState('');
    const { t,i18n }                 = useTranslation()
    const paginationComponentOptions = usePaginationComponentOptions();
/***************************************************************************************************************************/

    const getPartners = async (page) => {
		setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}partners?page=${page}&per_page=${perPage}&search=${_search}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    }
/***************************************************************************************************************************/
    const handlePageChange = page => {
        getPartners(page);
    };
/***************************************************************************************************************************/
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}partners?page=${page}&per_page=${newPerPage}&search=${_search}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setPerPage(newPerPage);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    };
/***************************************************************************************************************************/
    const setSearch = async(params) => { 

        setLoading(true);
        set_search(params.search);
        const headers = await getHeaders();
        await axios.get(`${url}partners?page=1&per_page=${perPage}&search=${params.search}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });

    }
/***************************************************************************************************************************/
    const deletePartner = (params) => {

        Swal.fire({
            title            : 'Delete Partner',
            text             : 'Do you want to delete this partner?',
            showCancelButton : true,
            confirmButtonText: 'Delete',
            customClass      : {
                confirmButton: 'btn btn-outline-danger',
                cancelButton : 'btn btn-primary mx-2'
            },
            buttonsStyling: false,
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    const headers = await getHeaders();
                    await axios.delete(`${url}partners/destroy/${params}`, {headers})
                            .then(res =>{
                                    Swal.fire({
                                        icon: 'success',
                                        title: res.data.response,
                                    });                        
                                    getPartners(1);
                            })
                            .catch(err =>{
                                    Swal.fire({
                                        icon: 'error',
                                        title: err?.response?.data?.response,
                                    });
                            })
                            .then(() =>{

                            })
                }
        })

    }
/***************************************************************************************************************************/
    useEffect(() => {
        getPartners(1)
    }, []);
/***************************************************************************************************************************/
    const columns = [
        {
            name: t('partners.name'),
            cell: row => {
                return <div className='w-100 text-start'>
                            <p className='text-capitalize mb-0'>{row.name}</p>     
                            <p className='text-capitalize mb-0'>{row.description}</p>           
                    </div>
            },
        },
        {
            name: t('partners.products_discount'),
            cell: row => <p className='text-capitalize list-group-item bg-body-tertiary'>{row.discount}%</p>   
        },
        {
            name: 'Descuento Especial SMP',
            cell: row => <p className='text-capitalize list-group-item bg-body-tertiary'>{row.discount_smp}%</p>   
        },
        {
            minWidth: '150px',
            name: t('partners.actions'),
            cell: (row, index, column, id) => {
                return <>                        
                            <div className="dropdown mb-2">
                                <a className="btn btn-sm btn-bd-primary btn-shadow dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: 120}}>
                                    <span>{t('partners.options')}</span>	
                                </a>
                                <ul className="dropdown-menu">
                                    <li>                                                                             
                                        <Link 
                                            className="dropdown-item fw-bold text-success" 
                                            to={`edit/${row.id}`}
                                        >
                                            <div className="row">
                                                <div className="col-2">
                                                    <FontAwesomeIcon icon={faPenToSquare} className='me-2'/>  
                                                </div>
                                                <div className="col-10">
                                                    <span>{t('partners.edit')}</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>                                      
                                        <a           
                                            href="#!"                      
                                            className={"dropdown-item fw-bold text-danger"}
                                            onClick={() => {
                                                deletePartner(row.id)
                                            }}      
                                        >
                                            <div className="row">
                                                <div className="col-2">
                                                    <FontAwesomeIcon icon={faTrash} className='me-2'/>
                                                </div>
                                                <div className="col-10">
                                                    <span>{t('partners.deleted')}</span>
                                                </div>
                                            </div>                                                                         
                                        </a>
                                    </li>
                                </ul>
                            </div>    
                    </>
            },
        },
    ];
/***************************************************************************************************************************/
    const ExpandedComponent = ({ data }) => {
        return <>
            <div className=''>
                <div className='row border border-5 border-white-subtle'>
                    <div className='col-4 border-end'>
                        <div className='w-100 bg-bs-primary text-center'>
                            <span className='text-white-subtle'>{t('partners.address')}</span>
                        </div>
                        <ul className='mt-2'>
                            {
                                <>
                                    <li className='text-capitalize list-group-item bg-body-tertiary'>{data.street_address}</li>   
                                    <li className='text-capitalize list-group-item bg-body-tertiary'>{data.address_line_2}</li>   
                                    <li className='text-capitalize list-group-item bg-body-tertiary'>{data.zip_code}</li>   
                                    <li className='text-capitalize list-group-item bg-body-tertiary'>{data.city}</li>   
                                    <li className='text-capitalize list-group-item bg-body-tertiary'>{data.state}</li>   
                                    <li className='text-capitalize list-group-item bg-body-tertiary'>{data.country}</li>   
                                </>
                            }
                        </ul>
                    </div>
                    <div className='col-4 border-end'>
                        <div className='w-100 bg-bs-primary text-center'>
                            <span className='text-white-subtle'>{t('partners.address_invoice')}</span>
                        </div>
                        <ul className='mt-2'>
                            {
                                <>
                                    <li className='text-capitalize list-group-item bg-body-tertiary'>{data.invoice_street_address}</li>   
                                    <li className='text-capitalize list-group-item bg-body-tertiary'>{data.invoice_address_line_2}</li>   
                                    <li className='text-capitalize list-group-item bg-body-tertiary'>{data.invoice_zip_code}</li>   
                                    <li className='text-capitalize list-group-item bg-body-tertiary'>{data.invoice_city}</li>   
                                    <li className='text-capitalize list-group-item bg-body-tertiary'>{data.invoice_state}</li>   
                                    <li className='text-capitalize list-group-item bg-body-tertiary'>{data.invoice_country}</li>   
                                    <li className='list-group-item bg-body-tertiary'>{data.invoice_email}</li>   
                                </>
                            }
                        </ul>
                    </div>
                    <div className='col-4'>
                        <div className='w-100 bg-bs-primary text-center'>
                            <span className='text-white-subtle'>{t('partners.users')}</span>
                        </div>
                        <ul className='mt-2'>
                            {
                                data?.Users.length > 0 
                                ?   data?.Users.map((e,i) => {
                                        return <li className='list-group-item bg-body-tertiary' key={`user-${e}-${i}`}>
                                            <div className='d-flex align-items-center'>
                                                <div className="me-2">
                                                    {e?.email}
                                                </div>
                                                {/* <div className="border border-success rounded border-2 p-1"> */}
                                                <div className="btn btn-sm btn-success p-0">
                                                    <Link to={`/users/edit/${e.id}`}>
                                                        <FontAwesomeIcon icon={faUserEdit} className='text-light'/>
                                                    </Link>
                                                </div>
                                            </div>
                                        </li> 
                                    })
                                :
                                    <li>Not users found :(</li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    }
/***************************************************************************************************************************/
  return (
    <div className='row align-items-center'>
            <div className="col-sm-4 col-md-4 col-lg-2 mb-3">
                <Link to="create" className='btn btn-bd-primary btn-shadow d-block'>
                    <FontAwesomeIcon icon={faHandshake} className="me-2"/>
                    <span className='text-capitalize fw-bold'>{t('partners.add_partner')}</span>
                </Link>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-10 mb-3">
                <Formik
                    initialValues={{ 
                        search: _search
                    }}
                    onSubmit={(values, actions) => setSearch(values, actions)}
                    enableReinitialize
                >
                    <Form>
                        <div className="input-group rounded-pill">
                            <Field 
                                type="text" 
                                className="form-control" 
                                placeholder={t('partners.search')} 
                                aria-label="Recipient's username"
                                aria-describedby="button-addon2"
                                name="search"
                            />                        
                            <button 
                                className="btn btn-outline-secondary" 
                                type="submit" 
                                id="button-addon2"
                            >
                                <FontAwesomeIcon icon={faMagnifyingGlass}/>
                            </button>
                        </div>
                    </Form>
                </Formik>
            </div>

            <div className="col-md-12">
                <div className="card card-shadow">
                    <DataTable
                        columns={columns}
                        data={data}
                        fixedHeader
                        fixedHeaderScrollHeight="1000px"
                        progressPending={loading}
                        pagination
                        paginationPerPage={perPage}
			            paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationComponentOptions={paginationComponentOptions}
                        paginationRowsPerPageOptions={[10, 20, 50, 100]}
                        progressComponent={<CustomLoader />}
                        dense
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                    />
                </div>
            </div>
        </div>
  )
}

export default Partners