import React, {useEffect, useState, useContext, Fragment} from 'react'
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next';
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from "jwt-decode";


const CreatePackages = () => {
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode = (token!==null) ? jwt_decode(token) : null;
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const { t }                                     = useTranslation();
    const [loading, setloading]                     = useState(false);
    const [charge, setCharge]                       = useState(false);
    const [products, setproducts]                   = useState([]);
    const [compatibility, setcompatibility]         = useState(false);
    const [compatibility_smp, setcompatibility_smp] = useState(false);
/***************************************************************************************************************************/

    const first = async() => { 
        const headers = await getHeaders();
        await axios.get(`${url}packages/get-products`, {headers})
                    .then(res =>{
                        setproducts(res.data.message)
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setCharge(true);
                    });
    }

/***************************************************************************************************************************/

    useEffect(() => {
        first()
    }, [])
    

/***************************************************************************************************************************/
    const createPackage = async(params, { resetForm }) => {
        setloading(true);
        const headers = await getHeaders();
        await axios.post(`${url}packages/store`, params, {headers})
                    .then(res =>{
                        Swal.fire({
                            icon: 'success',
                            title: res.data.response,
                        });
                        console.log(res);
                        resetForm();
                    })
                    .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err,
                        });
                        console.log(err)
                    })
                    .then(() =>{
                        setloading(false);
                    });
        console.log(params);
    }

/***************************************************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        quantity: Yup.number()
            .min(10, "min 10")
            .max(1000, "max 1000")
            .required(),
        comments: Yup.string()
            .min(1, "min 1")
            .max(255, "max 255 chars")
            .required(),
        product: Yup.number()
            .min(1)
            .required(),
        TimeId: Yup.number()
            .min(1).max(3)
            .required(),
        expiry_time: Yup.number()
            .min(1).max(31)
            .required(),
        compatibility: Yup.boolean()
            .required(),
        compatibility_smp: Yup.boolean()
            .required(),
    });

/*************************************************************************************************************************/
    
    const expiryTime = (params = 0, setFieldValue) => { 
        console.log(params);
        let cont = 0
        switch (parseInt(params)) {
            case 1:
                if(tokenDecode?.roles[0].name === 'admin'){
                    cont = 3;
                }else{
                    cont = 2;
                }
                break;
            case 2:
                cont = 25;
                break;
            case 3:
                cont = 32;
                break;
        
            default:
                break;
        }
        let td = [];
        for (let i = 1; i < cont; i++) {
            td.push(<option key={`time_expiricy-${i}`} value={i}>{i}</option>);
        }
        return td;
    }

/***************************************************************************************************************************/
    return (
        <div className='row'>
            <div className="col-md-12">
                <div className="card card-shadow">
                    <div className="card-header text-center">
                        {/* <h5 className=''>{t('packages.create.create_pack_licenses')}: <b>{tokenDecode.roles[0].name}</b></h5> */}
                        <h5 className=''>{t('packages.create.create_pack_licenses')}: <b>{t(`packages.create.${tokenDecode.roles[0].name}`)}</b></h5>
                    </div>
                    <div className="card-body">
                    <Formik
                        initialValues={{ 
                            quantity         : 0,
                            comments         : "",
                            product          : 0,
                            compatibility    : compatibility,
                            compatibility_smp: compatibility_smp,
                            TimeId           : 0,
                            expiry_time      : 0,
                        }}
                        validationSchema={formSchema}
                        onSubmit={(values, actions) => createPackage(values, actions)}
                    >
                        {({ values, setFieldValue  }) => (
                        <Form>
                            <div className='row justify-content-center'>
                                <div className='col-md-8 col-lg-8'>
                                    <main className='p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>                                                                

                                        <div className="mb-3">                                            
                                            <div className="form-floating">
                                                <Field type="text" className="form-control" id="quantity" placeholder="quantity" name="quantity"/>
                                                <label htmlFor="quantity">{t('packages.create.quantity')} (*)</label>
                                            </div>
                                            <ErrorMessage name="quantity" component="div" className='field-error text-danger fw-bold text-capitalize'/>                                                   
                                        </div>

                                        <div className='mb-3'>
                                        {
                                            products.length > 0 &&
                                            products.map((e,i) =>
                                                <Fragment key={`products-${i}`}>
                                                    <div className="form-check">
                                                        <input 
                                                            type="radio" 
                                                            className="form-check-input" 
                                                            id={`flex-${i}`} 
                                                            placeholder="product" 
                                                            name="product" 
                                                            value={e.id}
                                                            checked={values.product === e.id} 
                                                            onChange={() => setFieldValue('product', e.id)}
                                                        />
                                                        <label className="form-check-label text-capitalize" htmlFor={`flex-${i}`}>
                                                            {e.name}
                                                        </label>
                                                    </div>
                                                    </Fragment>
                                            )    
                                        }
                                            <ErrorMessage name="product" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>

                                        <div className="mb-3">
                                            {
                                                parseInt(values.product) === 1 
                                                ?   <>
                                                        <div className="form-check">
                                                            <input 
                                                                className={`form-check-input`} 
                                                                type="checkbox" 
                                                                id="compatibility" 
                                                                name="compatibility"
                                                                onChange={(e) => setFieldValue('compatibility', e.target.checked)}
                                                            />
                                                            <label className="form-check-label fw-bold text-capitalize" htmlFor="compatibility">
                                                                {t('packages.create.compatible')}
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input 
                                                                className={`form-check-input`} 
                                                                type="checkbox" 
                                                                id="compatibility_smp" 
                                                                name="compatibility_smp"
                                                                onChange={(e) => setFieldValue('compatibility_smp', e.target.checked)}
                                                            />
                                                            <label className="form-check-label fw-bold text-capitalize" htmlFor="compatibility_smp">
                                                                Compatible con Suite Mobile POS
                                                            </label>
                                                        </div>
                                                    </>
                                                :   <>
                                                        <input name="compatibility" value={false} type="hidden"/>
                                                        <input name="compatibility_smp" value={false} type="hidden"/>
                                                    </>
                                            }
                                        </div>

                                        <div className="mb-3">
                                            <Field 
                                                as="select" 
                                                className="form-select form-select-lg text-capitalize" 
                                                aria-label="Default select example" 
                                                name="TimeId"
                                                onChange={
                                                    (e)=>{
                                                        setFieldValue('TimeId', e.target.value);
                                                        setFieldValue('expiry_time', 0);
                                                    }
                                                }
                                            >
                                                <option value={0} disabled className="text-capitalize">{t('packages.create.select_an_time')}</option>
                                                {
                                                    tokenDecode?.roles[0]?.name === 'admin'
                                                    ?   ['Years', 'Months', 'Days'].map((e,i) => 
                                                            <option key={`time-${i}`} value={i+1}>{e}</option>
                                                        )
                                                    :   <option value={1}>{t('utilities.years')}</option>
                                                }                                                                                               
                                            </Field>
                                            <ErrorMessage name="TimeId" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>

                                        <div className="mb-3">
                                            <Field as="select" className="form-select form-select-lg text-capitalize" aria-label="Default select example" name="expiry_time">
                                                <option value={0} disabled className="text-capitalize">{t('packages.create.select_expire_time')}</option>
                                                { 
                                                    expiryTime(values.TimeId) 
                                                }
                                            </Field>
                                            <ErrorMessage name="expiry_time" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>
                            
                                        <div className="mb-3">                                    
                                            <div className="form-floating">
                                                <Field style={{height: '100px'}} as="textarea" type="text" className="form-control" id="comments" placeholder="name@example.com" name="comments"></Field>
                                                <label htmlFor="comments">{t('packages.create.comments')} (*)</label>
                                            </div>
                                            <ErrorMessage name="comments" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>

                                        <div className="mt-4">
                                            <button 
                                                className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" 
                                                type="submit"
                                                disabled={loading}
                                            ><span className='me-3 fw-bold'>
                                                {t('packages.create.create_package')}
                                            </span>
                                            {
                                                loading &&
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            }
                                            </button> 
                                        </div>

                                    </main>
                                </div>
                            </div>
                        </Form>
                        )}
                    </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreatePackages